import { Spinner } from 'spin.js';

export const q = (target) => {
  const nlToArr = (els) => Array.prototype.slice.call(els);
  const node = document.querySelector.bind(document);
  const nodeList = document.querySelectorAll.bind(document);
  const qs = (target) => node(target);
  const qsAll = (target) => nlToArr(nodeList(target));

  let els;
  els = qsAll(target);
  if (els.length <= 1) {
    els = qs(target);
  }
  return els;
}

export const clickAndEnterListener = (el, fn) => {
  if (el) {
    if (Array.isArray(el)) {
      el.forEach(x => {
        x.addEventListener('click', (e) => {
          e.preventDefault();
          fn(e);
        });
        x.addEventListener('keydown', (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            fn(e);
          };
        });
      });
    } else {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        fn(e);
      });
      el.addEventListener('keydown', (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          fn(e);
        };
      });
    }
  }
}

const spinner = new Spinner({
  lines: 10,
  color: '#000',
  animation: 'spinner-line-fade-more',
});

export function showSpinner() {
  const spinnerEl = q('.loading-spinner');
  spinnerEl.classList.add('show');
  spinner.spin(spinnerEl);
}

export function hideSpinner() {
  const spinnerEl = q('.loading-spinner');
  spinnerEl.classList.remove('show');
  spinner.stop(spinnerEl);
}

export function randomArrIndex(arr) {
  const max = arr.length;
  return Math.floor(Math.random() * max);
}

// https://bit.ly/38sODO0
export function shuffleArray(array) {
  let currentIndex = array.length;
  let randomIndex;
  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]
    ];
  }
  return array;
}

// https://bit.ly/3kALX3E
export function numberWithCommas(val) {
  let delimiter = ',';
  if (APP_locale) {
    if (APP_locale === 'es-xm') {
      delimiter = '.';
    } else if (APP_locale === 'zh-cn') {
      delimiter = ' ';
    }
  }
  const values = val.toString().split('.');
  return values[0].replace(/.(?=(?:.{3})+$)/g, `$&${delimiter}`) + (values.length == 2 ? '.' + values[1] : '');
}

// https://bit.ly/39Azjzv
export function numberNoCommas(val) {
  const newVal = val.toString().replace(/\,/g, '');
  return Number(newVal);
};

export function hideAndShowEls(el1, el2) {
  if (Array.isArray(el1)) {
    el1.forEach(el => el.classList.add('hide'));
  } else {
    el1.classList.add('hide');
  }
  if (Array.isArray(el2)) {
    el2.forEach(el => el.classList.remove('hide'));
  } else {
    el2.classList.remove('hide');
  }
}

export function forEachIfArray(target, fn) {
  if (Array.isArray(target)) {
    target.forEach(el => {
      fn(el);
    });
  } else {
    fn(target);
  }
}

export function makeId(val) {
  return val.replace(/\s+/g, '-').toLowerCase();
}

export const validateEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase()); // returns true or false based on regex
}

export function trackingAttr(cardType, section, id) {
  return 'AMEXECM1-' + cardType + '-' + section + '|' + id + '|external';
}

export function getElementDistanceFromTop(el) {
  return window.pageYOffset + el.getBoundingClientRect().top;
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) {
    return '';
  }
  const formattedPhoneNumber = phoneNumber.replace('+', '');
  return formattedPhoneNumber;
}

export function stripHtml(html) { // https://bit.ly/44dn8z2
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}
