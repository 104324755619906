import './bootstrap';
import {q} from './utilities';
import {
  initBenefitsSwitcher,
  initCathaySwipers,
  initFlipCards,
  initPartnerLinkFormPage,
  initPartnerPromoForm,
  initUnitedLandingPage,
} from './partners/partner';
import {initGlobalLanding} from './global-landing';
import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

if (q('#united-landing-page')) {
  initUnitedLandingPage();
}

if (
  q('#partner-link-form-page') ||
  q('#cathay-link-form-page')
) {
  initPartnerLinkFormPage();
}

if (
  q('#partner-promo-register')
) {
  initPartnerPromoForm();
}

if (q('#global-landing-page')) {
  initGlobalLanding();
}

if (q('.cathay-page .benefit-cards') || q('.singapore-page .benefit-cards')) {
  initFlipCards();
}

if (q('#cathay-landing-page') || q('#cathay-signin-page') || q('#cathay-success-page')) {
  initCathaySwipers();
}

if(q('#singapore-landing-page')) {
  initBenefitsSwitcher();
  initFlipCards();
}
